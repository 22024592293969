<template>
    <TabsRoot v-bind="forwarded">
        <slot />
    </TabsRoot>
</template>
<script setup lang="ts">
import type { TabsRootEmits, TabsRootProps } from "reka-ui";
import { TabsRoot, useForwardPropsEmits } from "reka-ui";

const props = defineProps<TabsRootProps>();
const emits = defineEmits<TabsRootEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>
